import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import DragPanes from 'highcharts/modules/drag-panes';
import AnnotationsAdvanced from 'highcharts/modules/annotations-advanced';
import PriceIndicator from 'highcharts/modules/price-indicator';
import IndicatorsAll from 'highcharts/indicators/indicators-all';
import StockTools from 'highcharts/modules/stock-tools';
import HeikinAshi from 'highcharts/modules/heikinashi';
import HollowCandlestick from 'highcharts/modules/hollowcandlestick';
import Accessibility from 'highcharts/modules/accessibility';
import { useUI } from '../context/UIContext';
import PopUp from './PopUp';
import 'highcharts/css/annotations/popup.css';
import './chart.css'
import { setChartInstance } from '../../utils/chartUtils';

DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
IndicatorsAll(Highcharts);
StockTools(Highcharts);
HeikinAshi(Highcharts);
HollowCandlestick(Highcharts);
Accessibility(Highcharts);

const Chart = ({setLoading}) => {
  const chartRef = useRef(null);

  const { setActiveButton, isFullScreen, showTables, setMainSeriesType } = useUI();
  const [annotation, setAnnotation] = useState(null);

  useEffect(() => {
    const loadChart = async () => {
      const response = await fetch('https://demo-live-data.highcharts.com/aapl-ohlcv.json');
      const data = await response.json();

      const ohlc = data.map(item => [item[0], item[1], item[2], item[3], item[4]]);
      const volume = data.map(item => [item[0], item[5]]);

      if (!chartRef.current) { // Asegura que el gráfico solo se inicialice una vez
        chartRef.current = Highcharts.stockChart('container', {
          chart: {
            backgroundColor: 'var(--chart-bg)',
            events: {

              load: function () {
                console.log('Chart has been loaded');
              },
              addSeries: function (e) {
                console.log('Series has been added', e);
                setActiveButton(null);
              }
            }
          },
          xAxis: {
            labels: { style: { color: 'var(--chart-text)' } },
          },
          yAxis: [
            { labels: { align: 'left', style: { color: 'var(--chart-text)' } }, gridLineColor: 'var(--chart-lines)', height: '80%', resize: { enabled: true } },
            { labels: { align: 'left', style: { color: 'var(--chart-text)' } }, gridLineColor: 'var(--chart-lines)', top: '80%', height: '20%', offset: 0 }
          ],
          stockTools: { gui: { enabled: false } },
          series: [
            {
              type: 'candlestick', id: 'price', name: 'AAPL Stock Price', data: ohlc,
              upColor: 'var(--chart-up)', upLineColor: 'var(--chart-up)',
              color: 'var(--chart-down)', lineColor: 'var(--chart-down)'
            },
            {
              type: 'column', id: 'volume', name: 'AAPL Volume', data: volume, yAxis: 1, color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                  [0, 'var(--chart-volume)'],
                  [1, '#fff']
                ]
              }
            }
          ],
          navigator: { enabled: false },
          rangeSelector: {
            buttonTheme: {
              fill: 'var(--chart-btn-bg)',
              style: {
                color: 'var(--chart-text)',
              },
              states: {
                hover: {
                  fill: 'var(--chart-btn-hover)',
                  style: {
                    color: 'var(--chart-btn-hover-text)',
                  },
                },
                select: {
                  fill: 'var(--bee-yellow)',
                  style: {
                    color: 'var(--chart-btn-text)',
                  },
                },
              },
            },
            labelStyle: {
              color: 'var(--chart-text)',
            },
            inputStyle: {
              color: 'var(--chart-text)',
            }
          },
          navigation: {
            annotationsOptions: {
              shapeOptions: {
                fill: 'var(--chart-annotation)',
                stroke: 'var(--chart-annotation)',
              },
              typeOptions: {
                connector: {
                  stroke: 'var(--chart-annotation)',
                }
              },

            },
            events: {
              showPopup: function (e) {

                console.log('Popup has been shown', chartRef);
                console.log(e);

                // Verifica si se está mostrando el popup de anotación
                if (e.formType === 'annotation-toolbar' || e.formType === 'flag') {
                  
                  setAnnotation(e.annotation || e);
                  const highchartsPopup = document.querySelector('.highcharts-popup.highcharts-no-tooltip');
                  if (highchartsPopup) {
                    highchartsPopup.style.display = 'none';
                  }

                  const popup = document.getElementById('annotation-popup');
                  if (popup) {
                    popup.style.display = 'flex';
                  }
                  // Si se muestra el popup de indicadores, cierra el popup de anotaciones
                } else {
                  const popup = document.getElementById('annotation-popup');
                  if (popup) {
                    popup.style.display = 'none';
                  }
                  setAnnotation(null);
                }

              },

              closePopup: function () {
                const popup = document.getElementById('annotation-popup');
                if (popup) {
                  popup.style.display = 'none';
                }
              },
            }
          }
        });
        setChartInstance(chartRef.current);
        addEventListeners(chartRef.current);
        setLoading(false);
      }

    };



    loadChart();
  }, []);


  // Definir eventos una vez que el gráfico está disponible
  const addEventListeners = (chart) => {

    setMainSeriesType(chart.series[0].type);

    if (!chart.hasAddedEvents) {
      chart.hasAddedEvents = true;

      document.querySelector('.current-price-btn-hide').addEventListener('click', function () {
        const series = chart.series[0];
        const isPriceIndicatorEnabled = series.options.lastPrice?.enabled;

        series.update({
          lastPrice: {
            enabled: !isPriceIndicatorEnabled,
            color: 'red'
          },
          lastVisiblePrice: {
            enabled: !isPriceIndicatorEnabled,
            label: {
              enabled: !isPriceIndicatorEnabled,
              style: {
                color: 'white',
                fontWeight: 'bold'
              },
              backgroundColor: 'black'
            }
          }
        });
      });

      Highcharts.addEvent(Highcharts.Annotation, 'add', function (e) {
        var chart = e.target.chart;

        // Escuchar el evento de clic en el gráfico
        Highcharts.addEvent(chart.container, 'click', function (event) {
          var popup = document.getElementById('annotation-popup');
          
          if (popup) {
            // Obtener las dimensiones del popup
            var popupWidth = popup.offsetWidth;

            // Calcula la posición del popup basada en la ubicación del clic y el ancho del contenedor
            var leftPos = event.pageX + 20; // Posición inicial a la derecha del clic
            var rightEdge = chart.container.getBoundingClientRect().right; // Borde derecho del contenedor del gráfico

            // Comprueba si el popup se desborda del contenedor del gráfico, y ajusta si es necesario
            if (leftPos + popupWidth > rightEdge) {
              leftPos = event.pageX - popupWidth - 20; // Posición a la izquierda del clic si no hay espacio a la derecha
            }

            popup.style.left = leftPos + 'px';
            popup.style.top = event.pageY + 'px';

          } else {
            console.error('No se encontró el elemento con id "annotation-popup".');
          }
        });

        // Acciones adicionales después de añadir la anotación
        setActiveButton(null);
        console.log('Nueva anotación añadida y clase active removida si estaba presente.', e.target.options.langKey);
      });



    }
  };


  return (
    <div className='chart-container'>
      <div id="container" style={{
        width: '100%',
        height: isFullScreen ? '100vh' : '95%',
        margin: isFullScreen ? '0' : 'auto',
        paddingRight: showTables ? '0' : '25px'
      }} />

      <PopUp annotation={annotation} />
    </div>
  );
};

export default Chart;