import React, { useEffect, useRef, useState } from "react";
import { useUI } from '../context/UIContext';
import "./stocktools.css"; // Archivo CSS personalizado

const StockTools = () => {

  const { showTables, toggleTables, activeButton, setActiveButton, 
    currentPrice, toggleCurrentPrice, showAnnotations, toggleAnnotations,
    handleShowModal, showSelectFutureModal, showCompareFuturesModal, showSavedChartsModal, showChangeColorsModal,
    isFullScreen, toggleFullScreen, mainSeriesType, setMainSeriesType } = useUI();
  
  const containerRef = useRef(null);
  const [paddingLeft, setPaddingLeft] = useState(0);
  const [paddingTop, setPaddingTop] = useState(0);
  useEffect(() => {
    const container = containerRef.current;
  
    const handleMouseMove = (e) => {
      const rect = container.getBoundingClientRect();
      const x = e.clientX - rect.left; // Posición X del cursor relativa al contenedor
      const y = e.clientY - rect.top; // Posición Y del cursor relativa al contenedor
  
      if (container.scrollWidth > rect.width || container.scrollHeight > rect.height) { 
        // Calcular el centro del contenedor para X y Y
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;
  
        // Calcular la distancia del cursor al centro en X y Y
        const distanceFromCenterX = x - centerX;
        const distanceFromCenterY = y - centerY;
  
        // Reducir la sensibilidad cerca del centro y aumentarla hacia los extremos usando el cuadrado de la distancia
        const scrollPercentageX = (distanceFromCenterX / centerX) * (distanceFromCenterX / centerX);
        const scrollPercentageY = (distanceFromCenterY / centerY) * (distanceFromCenterY / centerY);
  
        // Ajustar la dirección basada en la posición del cursor
        const directionX = distanceFromCenterX > 0 ? 1 : -1;
        const directionY = distanceFromCenterY > 0 ? 1 : -1;
  
        // Calcular el máximo desplazamiento permitido en X y Y
        const maxScrollLeft = container.scrollWidth - rect.width;
        const maxScrollTop = container.scrollHeight - rect.height;
  
        // Ajustar el desplazamiento basado en el porcentaje y la dirección
        container.scrollLeft = (maxScrollLeft / 2) + (directionX * scrollPercentageX * (maxScrollLeft / 2));
        container.scrollTop = (maxScrollTop / 2) + (directionY * scrollPercentageY * (maxScrollTop / 2));
  
        // Asegurarse de que los valores de scroll no excedan los límites del contenedor
        container.scrollLeft = Math.max(0, Math.min(maxScrollLeft, container.scrollLeft));
        container.scrollTop = Math.max(0, Math.min(maxScrollTop, container.scrollTop));
      }
    };
  
    container.addEventListener('mousemove', handleMouseMove);
  
    return () => {
      container.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);  

  useEffect(() => {
    const checkSizeAndUpdatePaddingLeft = () => {
      const container = containerRef.current;
      if (container) {
        const width = container.offsetWidth; 
        if (width < 280) {
          const newPadding = (280 - width);
          setPaddingLeft(newPadding);
        } else {
          setPaddingLeft(0); 
        }
      }
    };

    window.addEventListener('resize', checkSizeAndUpdatePaddingLeft);
    checkSizeAndUpdatePaddingLeft(); 

    return () => {
      window.removeEventListener('resize', checkSizeAndUpdatePaddingLeft);
    };
  }, []);

  useEffect(() => {
    const checkSizeAndUpdatePaddingTop = () => {
      const container = containerRef.current;
      if (container) {
        const height = container.offsetHeight; 
        
        if (height < 790) {
          const newPadding = (790 - height);
          setPaddingTop(newPadding);
        } else {
          setPaddingTop(0);
        }
      }
    };

    window.addEventListener('resize', checkSizeAndUpdatePaddingTop);
    checkSizeAndUpdatePaddingTop(); 

    return () => {
      window.removeEventListener('resize', checkSizeAndUpdatePaddingTop);
    };
  }, []);

  return (
      <div ref={containerRef} className="hex-container highcharts-stocktools-wrapper highcharts-bindings-container highcharts-bindings-wrapper" style={{ paddingLeft: `${paddingLeft}px`, paddingTop: `${paddingTop}px` }}>
        <div className="hex-row row-1" style={{ marginTop: '10px' }}>
          <div className={`hex-item symbol-btn ${showSelectFutureModal === true ? 'active' : ''}`} 
            title="Seleccionar futuro" onClick={() => handleShowModal('SelectFutureModal')}/>
          <div className="hex-item interval-btn" title="Intervalo"/>
          <div className={`hex-item compare-btn ${showCompareFuturesModal === true ? 'active' : ''}`} 
            title="Comparar futuros" onClick={() => handleShowModal('CompareFuturesModal')}/>
          <div className={`hex-item highcharts-indicators ${activeButton === 'indicators-btn' ? 'active' : ''}`} 
            title="Indicators" onClick={() => {
              // Alternar el estado del botón basado en su estado actual
              if (activeButton === 'indicators-btn') {
                // Desactivar el botón y asegurar que el popup se oculte si es visible
                setActiveButton(null);
                const popup = document.querySelector('.highcharts-popup');
                if (popup) {
                  popup.style.display = 'none'; // Ocultar el popup
                }
              } else {
                // Activar el botón y configurar el evento en el popup si no ha sido configurado
                setActiveButton('indicators-btn');
                const popup = document.querySelector('.highcharts-popup-close');
                if (popup && !popup.__isEventSet) {
                  popup.addEventListener('click', () => {
                    setActiveButton(null);
                    const popup = document.querySelector('.highcharts-popup');
                    if (popup) {
                      popup.style.display = 'none'; // Asegurar que el popup se oculte cuando se cierra
                    }
                  });
                  popup.__isEventSet = true; // Marcar que el evento ha sido establecido
                }
              }
            }}/>
          <div className={`hex-item highcharts-label-annotation ${activeButton === 'label-btn' ? 'active' : ''}`} 
            title="Label" onClick={() => setActiveButton(activeButton === 'label-btn' ? null : 'label-btn')}/>
        </div>
        <div className="hex-row row-2">
          <div className={`hex-item highcharts-ellipse-annotation ${activeButton === 'ellipse-btn' ? 'active' : ''}`} 
            title="Ellipse" onClick={() => setActiveButton(activeButton === 'ellipse-btn' ? null : 'ellipse-btn')}/>
          <div className={`hex-item highcharts-circle-annotation ${activeButton === 'circle-btn' ? 'active' : ''}`} 
            title="Circle" onClick={() => setActiveButton(activeButton === 'circle-btn' ? null : 'circle-btn')}/>
          <div className={`hex-item highcharts-rectangle-annotation ${activeButton === 'rectangle-btn' ? 'active' : ''}`} 
            title="Rectangle" onClick={() => setActiveButton(activeButton === 'rectangle-btn' ? null : 'rectangle-btn')}/>
          <div className="hex-item unclickable"/>
        </div>
        <div className="hex-row row-3">
          <div className={`hex-item highcharts-segment ${activeButton === 'segment-btn' ? 'active' : ''}`} 
            title="Segment" onClick={() => setActiveButton(activeButton === 'segment-btn' ? null : 'segment-btn')}/>
          <div className={`hex-item highcharts-arrow-segment ${activeButton === 'arrow-segment-btn' ? 'active' : ''}`} 
            title="Arrow Segment" onClick={() => setActiveButton(activeButton === 'arrow-segment-btn' ? null : 'arrow-segment-btn')}/>
          <div className={`hex-item highcharts-ray ${activeButton === 'ray-btn' ? 'active' : ''}`} 
            title="Ray" onClick={() => setActiveButton(activeButton === 'ray-btn' ? null : 'ray-btn')}/>
          <div className={`hex-item highcharts-arrow-ray ${activeButton === 'arrow-ray-btn' ? 'active' : ''}`} 
            title="Arrow Ray" onClick={() => setActiveButton(activeButton === 'arrow-ray-btn' ? null : 'arrow-ray-btn')}/>
          <div className="hex-item unclickable"/>
        </div>
        <div className="hex-row row-4">
          <div className={`hex-item highcharts-infinity-line ${activeButton === 'infinity-line-btn' ? 'active' : ''}`} 
            title="Line" onClick={() => setActiveButton(activeButton === 'infinity-line-btn' ? null : 'infinity-line-btn')}/>
          <div className={`hex-item highcharts-arrow-infinity-line ${activeButton === 'arrow-infinity-line-btn' ? 'active' : ''}`} 
            title="Arrow Line" onClick={() => setActiveButton(activeButton === 'arrow-infinity-line-btn' ? null : 'arrow-infinity-line-btn')}/>
          <div className={`hex-item highcharts-horizontal-line ${activeButton === 'horizontal-line-btn' ? 'active' : ''}`} 
            title="Horizontal Line" onClick={() => setActiveButton(activeButton === 'horizontal-line-btn' ? null : 'horizontal-line-btn')}/>
          <div className={`hex-item highcharts-vertical-line ${activeButton === 'vertical-line-btn' ? 'active' : ''}`} 
            title="Vertical Line" onClick={() => setActiveButton(activeButton === 'vertical-line-btn' ? null : 'vertical-line-btn')}/>
        </div>
        <div className="hex-row row-5">
          <div className={`hex-item highcharts-elliott3 ${activeButton === 'elliott3-btn' ? 'active' : ''}`} 
            title="Elliott 3" onClick={() => setActiveButton(activeButton === 'elliott3-btn' ? null : 'elliott3-btn')}/>
          <div className={`hex-item highcharts-elliott5 ${activeButton === 'elliott5-btn' ? 'active' : ''}`} 
            title="Elliott 5" onClick={() => setActiveButton(activeButton === 'elliott5-btn' ? null : 'elliott5-btn')}/>
          <div className={`hex-item highcharts-crooked3 ${activeButton === 'crooked3-btn' ? 'active' : ''}`} 
            title="Crooked 3" onClick={() => setActiveButton(activeButton === 'crooked3-btn' ? null : 'crooked3-btn')}/>
          <div className={`hex-item highcharts-crooked5 ${activeButton === 'crooked5-btn' ? 'active' : ''}`} 
            title="Crooked 5" onClick={() => setActiveButton(activeButton === 'crooked5-btn' ? null : 'crooked5-btn')}/>
          <div className="hex-item unclickable"/>
        </div>
        <div className="hex-row row-6">
          <div className={`hex-item highcharts-measure-xy ${activeButton === 'measure-xy-btn' ? 'active' : ''}`} 
            title="Measure XY" onClick={() => setActiveButton(activeButton === 'measure-xy-btn' ? null : 'measure-xy-btn')}/>
          <div className={`hex-item highcharts-measure-x ${activeButton === 'measure-x-btn' ? 'active' : ''}`} 
            title="Measure X" onClick={() => setActiveButton(activeButton === 'measure-x-btn' ? null : 'measure-x-btn')}/>
          <div className={`hex-item highcharts-measure-y ${activeButton === 'measure-y-btn' ? 'active' : ''}`} 
            title="Measure Y" onClick={() => setActiveButton(activeButton === 'measure-y-btn' ? null : 'measure-y-btn')}/>
          <div className="hex-item unclickable"/>
        </div>
        <div className="hex-row row-7">
          <div className="hex-item unclickable"/>
          <div className={`hex-item highcharts-fibonacci ${activeButton === 'fibonacci-btn' ? 'active' : ''}`} 
            title="Fibonacci" onClick={() => setActiveButton(activeButton === 'fibonacci-btn' ? null : 'fibonacci-btn')}/>
          <div className={`hex-item highcharts-fibonacci-time-zones ${activeButton === 'fibonacci-time-zones-btn' ? 'active' : ''}`} 
            title="Fibonacci Time Zones" onClick={() => setActiveButton(activeButton === 'fibonacci-time-zones-btn' ? null : 'fibonacci-time-zones-btn')}/>
          <div className={`hex-item highcharts-pitchfork ${activeButton === 'pitchfork-btn' ? 'active' : ''}`} 
            title="Pitchfork" onClick={() => setActiveButton(activeButton === 'pitchfork-btn' ? null : 'pitchfork-btn')}/>
          <div className="hex-item unclickable"/>
        </div>
        <div className="hex-row row-8">
          <div className="hex-item unclickable"/>
          <div className={`hex-item highcharts-parallel-channel ${activeButton === 'parallel-channel-btn' ? 'active' : ''}`} 
            title="Parallel Channel" onClick={() => setActiveButton(activeButton === 'parallel-channel-btn' ? null : 'parallel-channel-btn')}/>
          <div className={`hex-item highcharts-time-cycles ${activeButton === 'time-cycles-btn' ? 'active' : ''}`} 
            title="Time Cycles" onClick={() => setActiveButton(activeButton === 'time-cycles-btn' ? null : 'time-cycles-btn')}/>
          <div className="hex-item unclickable"/>
        </div>
        <div className="hex-row row-9">
          <div className="hex-item unclickable"/>
          <div className={`hex-item highcharts-vertical-counter ${activeButton === 'vertical-counter-btn' ? 'active' : ''}`} 
            title="Vertical Counter" onClick={() => setActiveButton(activeButton === 'vertical-counter-btn' ? null : 'vertical-counter-btn')}/>
          <div className={`hex-item highcharts-vertical-label ${activeButton === 'vertical-label-btn' ? 'active' : ''}`} 
            title="Vertical Label" onClick={() => setActiveButton(activeButton === 'vertical-label-btn' ? null : 'vertical-label-btn')}/>
          <div className={`hex-item highcharts-vertical-arrow ${activeButton === 'vertical-arrow-btn' ? 'active' : ''}`} 
            title="Vertical Arrow" onClick={() => setActiveButton(activeButton === 'vertical-arrow-btn' ? null : 'vertical-arrow-btn')}/>
          <div className="hex-item unclickable"/>
        </div>
        <div className="hex-row row-10">
          <div className={`hex-item highcharts-flag-circlepin ${activeButton === 'flag-circlepin-btn' ? 'active' : ''}`} 
            title="Flag Circle" onClick={() => setActiveButton(activeButton === 'flag-circlepin-btn' ? null : 'flag-circlepin-btn')}/>
          <div className={`hex-item highcharts-flag-diamondpin ${activeButton === 'flag-diamondpin-btn' ? 'active' : ''}`} 
            title="Flag Diamond" onClick={() => setActiveButton(activeButton === 'flag-diamondpin-btn' ? null : 'flag-diamondpin-btn')}/>
          <div className={`hex-item highcharts-flag-squarepin ${activeButton === 'flag-squarepin-btn' ? 'active' : ''}`} 
            title="Flag Square" onClick={() => setActiveButton(activeButton === 'flag-squarepin-btn' ? null : 'flag-squarepin-btn')}/>
          <div className={`hex-item highcharts-flag-simplepin ${activeButton === 'flag-simplepin-btn' ? 'active' : ''}`} 
            title="Flag Simple" onClick={() => setActiveButton(activeButton === 'flag-simplepin-btn' ? null : 'flag-simplepin-btn')}/>
        </div>
        <div className="hex-row row-11">
          <div className="hex-item unclickable"/>
          <div className={`hex-item highcharts-series-type-ohlc ${mainSeriesType === 'ohlc' ? 'active' : ''}`}
            title="OHLC" onClick={() => setMainSeriesType('ohlc')}/>
          <div className={`hex-item highcharts-series-type-hlc ${mainSeriesType === 'hlc' ? 'active' : ''}`} 
            title="HLC" onClick={() => setMainSeriesType('hlc')}/>
          <div className={`hex-item highcharts-series-type-line ${mainSeriesType === 'line' ? 'active' : ''}`} 
            title="Line" onClick={() => setMainSeriesType('line')}/>
          <div className="hex-item unclickable"/>
        </div>
        <div className="hex-row row-12">
          <div className={`hex-item highcharts-series-type-candlestick ${mainSeriesType === 'candlestick' ? 'active' : ''}`} 
            title="Candlestick" onClick={() => setMainSeriesType('candlestick')}/>
          <div className={`hex-item highcharts-series-type-heikinashi ${mainSeriesType === 'heikinashi' ? 'active' : ''}`} 
            title="Heikin Ashi" onClick={() => setMainSeriesType('heikinashi')}/>
          <div className={`hex-item highcharts-series-type-hollowcandlestick ${mainSeriesType === 'hollowcandlestick' ? 'active' : ''}`} 
            title="Hollow Candlestick" onClick={() => setMainSeriesType('hollowcandlestick')}/>
          <div className={`hex-item colors-btn ${showChangeColorsModal === true ? 'active' : ''}`}
            title="Personalizar colores del grafico" onClick={() => handleShowModal('ChangeColorsModal')}/>
        </div>
        <div className="hex-row row-13">
          <div className="hex-item unclickable"/>
          <div className={`hex-item highcharts-save-chart ${activeButton === 'save-chart-btn' ? 'active' : ''}`} 
            title="Save Chart" />
          <div className={`hex-item saved-charts-btn ${showSavedChartsModal === true ? 'active' : ''}`} 
            title="Graficos Guardados" onClick={() => handleShowModal('SavedChartsModal')}/>
          <div className={`hex-item highcharts-full-screen ${isFullScreen === true ? 'active' : ''}`}
            title="Full Screen" onClick={toggleFullScreen}/>
          <div className="hex-item unclickable"/>
        </div>
        <div className="hex-row row-14" style={{ marginBottom: '10px' }}>
          <div className="hex-item unclickable"/>
          <div className={`hex-item current-price-btn-${currentPrice === false ? 'hide' : 'show active'}`} 
            title="Current Price Indicator" onClick={toggleCurrentPrice}/>
          <div className={`hex-item highcharts-toggle-annotations annotations-btn-${showAnnotations === false ? 'hide active' : 'show'}`} 
            title="Toggle Annotations" onClick={toggleAnnotations}/>
          <div className={`hex-item ${showTables ? 'show-tables-btn' : 'hide-tables-btn'}`} 
            title={showTables ? 'Ocultar Tablas' : 'Mostrar Tablas'} onClick={toggleTables}/>
        </div>
      </div>
  );
};

export default StockTools;