import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RgbaColorPicker, HexColorPicker } from "react-colorful";

// Componente reutilizable para los selectores de colores
const ColorPickerField = ({ title, color, setColor, activePicker, setActivePicker, pickerType, colorFormat }) => (
  <div className="color-picker-field">
    <div
      className="color-box"
      style={{ backgroundColor: colorFormat === 'rgba' ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : color }}
      onClick={() => setActivePicker(activePicker === pickerType ? null : pickerType)}
      title={title}
    />
    {activePicker === pickerType && (
      <>
        {colorFormat === 'rgba' ? (
          <RgbaColorPicker color={color} onChange={setColor} />
        ) : (
          <HexColorPicker color={color} onChange={setColor} />
        )}
      </>
    )}
  </div>
);

const PopUp = React.memo(({ annotation }) => {
  const [activePicker, setActivePicker] = useState(null);
  const pickerRef = useRef(null);

  // Estados de color para varios casos
  const initialColorStates = {
    textColor: '#000000',
    backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
    borderColor: { r: 0, g: 0, b: 0, a: 1 },
    fontSize: 16,
    lineColor: '#000000',
    lineSize: 1,
    measureText: '#000000',
    measureWidth: 1,
    measureBackground: { r: 255, g: 255, b: 255, a: 1 },
    colorX: '#000000',
    widthX: 1,
    colorY: '#000000',
    widthY: 1,
    timeColor: { r: 0, g: 0, b: 0, a: 1 },
    timeWidth: 1,
    fibonacciBackground: { r: 0, g: 0, b: 0, a: 1 },
    fibonacciLabels: '#000000',
    fibonacciLabelsSize: 10,
    fibonacciLines: { r: 0, g: 0, b: 0, a: 1 },
    pitchforkInner: { r: 0, g: 0, b: 0, a: 1 },
    pitchforkOuter: { r: 0, g: 0, b: 0, a: 1 },
    pitchforkLines: { r: 0, g: 0, b: 0, a: 1 },
    tunnelBackground: { r: 0, g: 0, b: 0, a: 1 },
    tunnelLines: { r: 0, g: 0, b: 0, a: 1 },
    verticalConnector: { r: 0, g: 0, b: 0, a: 1 },
    verticalLabel: { r: 0, g: 0, b: 0, a: 1 },
    elliottWaveColor: '#000000',
    elliottWaveSize: 10,
    flagText: '',
    flagName: '',
  };

  const [colors, setColors] = useState(initialColorStates);

  // Función para generar colores similares
  const generateSimilarColors = (baseColor, numberOfColors) => {
    const colors = [baseColor]; // Iniciar con el color base

    // Generar colores similares variando ligeramente los valores de RGB
    for (let i = 1; i < numberOfColors; i++) {
      const variation = i * 25; // Variación en el color (puedes ajustar este valor)

      // Crear un nuevo color con la variación, asegurando que los valores no excedan los límites de 0 a 255
      const newColor = {
        r: Math.max(0, Math.min(255, baseColor.r + variation)),
        g: Math.max(0, Math.min(255, baseColor.g + variation)),
        b: Math.max(0, Math.min(255, baseColor.b + variation)),
        a: baseColor.a // Mantener la misma opacidad
      };

      colors.push(newColor); // Agregar el color generado a la lista
    }

    return colors;
  };

  const updateAnnotation = useCallback((field, value) => {
    setColors(prev => ({ ...prev, [field]: value }));

    if (!annotation) return;

    switch (field) {
      case 'textColor':
        annotation.update({
          labelOptions: {
            style: {
              color: value,
            },
          },
        });
        break;

      case 'backgroundColor':
        // Verificamos si es una anotación de tipo "label" o "shape"
        if (annotation.options.shapes && annotation.options.shapes.length > 0) {
          // Si es una anotación de forma (como un círculo, elipse o rectángulo)
          annotation.update({
            shapes: annotation.options.shapes.map(shape => ({
              ...shape,
              fill: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})` // Actualizar color de fondo para shapes
            }))
          });
        } else {
          // Si es una anotación de tipo "label"
          annotation.update({
            labelOptions: {
              backgroundColor: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`, // Actualizar color de fondo para etiquetas
            }
          });
        }
        break;


      case 'borderColor':
        // Verificamos si es una anotación de tipo "shape"
        if (annotation.options.shapes && annotation.options.shapes.length > 0) {
          // Si es una anotación de forma (como un círculo, elipse o rectángulo)
          annotation.update({
            shapes: annotation.options.shapes.map(shape => ({
              ...shape,
              stroke: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})` // Actualizar color del borde para shapes
            }))
          });
        } else {
          // Si es una anotación de tipo "label"
          annotation.update({
            labelOptions: {
              borderColor: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`, // Actualizar color del borde para etiquetas
            }
          });
        }
        break;

      case 'fontSize':
        annotation.update({
          labelOptions: {
            style: {
              fontSize: `${value}px`,
            },
          },
        });
        break;

      case 'lineColor':
        annotation.update({
          typeOptions: {
            line: {
              stroke: value,
            },
          },
        });
        break;

      case 'lineSize':
        annotation.update({
          typeOptions: {
            line: {
              strokeWidth: value,
            },
          },
        });
        break;

      case 'elliottWaveColor':
        annotation.labels.forEach(label => {
          label.update({
            style: {
              color: value,
            },
          });
        });
        break;

      case 'elliottWaveSize':
        annotation.labels.forEach(label => {
          label.update({
            style: {
              fontSize: `${value}px`,
            },
          });
        });
        break;


      case 'measureBackground':
        annotation.update({
          typeOptions: {
            background: {
              fill: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
            }
          },
        });
        break;

      case 'measureText':
        annotation.update({
          typeOptions: {
            label: {
              style: {
                color: value,
              },
            },
          },
        });
        break;

      case 'measureWidth':
        annotation.update({
          typeOptions: {
            label: {
              style: {
                fontSize: `${value}px`,
              },
            },
          },
        });
        break;

      case 'colorX':
        annotation.update({
          typeOptions: {
            crosshairX: {
              stroke: value,
            },
          },
          shapeOptions: {
            fill: 'none'
          }
        });
        break;

      case 'widthX':
        annotation.update({
          typeOptions: {
            crosshairX: {
              strokeWidth: value,
            },
          },
        });
        break;

      case 'colorY':
        annotation.update({
          typeOptions: {
            crosshairY: {
              stroke: value,
            },
          },
          shapeOptions: {
            fill: 'none'
          }
        });
        break;

      case 'widthY':
        annotation.update({
          typeOptions: {
            crosshairY: {
              strokeWidth: value,
            },
          },
        });
        break;

      case 'timeColor':
        annotation.update({
          typeOptions: {
            line: {
              stroke: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
            },
          },
        });
        break;

      case 'timeWidth':
        annotation.update({
          typeOptions: {
            line: {
              strokeWidth: value,
            },
          },
        });
        break;

      case 'fibonacciBackground':
        // Similar colors generation logic (optional, depending on implementation)
        const similarColors = generateSimilarColors(value, 6);
        annotation.update({
          typeOptions: {
            backgroundColors: similarColors.reduce((acc, color, index) => {
              acc[index] = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
              return acc;
            }, {}),
          },
        });
        break;

      case 'fibonacciLines':
        annotation.update({
          typeOptions: {
            lineColor: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
          },
        });
        break;

      case 'fibonacciLabels':
        const updatedLabels = annotation.options.typeOptions.labels.map(label => ({
          ...label,
          style: {
            ...label.style,
            color: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
          },
        }));
        annotation.update({
          typeOptions: {
            labels: updatedLabels,
          },
        });
        break;

      case 'fibonacciLabelsSize':
        const updatedLabelSizes = annotation.options.typeOptions.labels.map(label => ({
          ...label,
          style: {
            ...label.style,
            fontSize: `${value}px`,
          },
        }));
        annotation.update({
          typeOptions: {
            labels: updatedLabelSizes,
          },
        });
        break;

      case 'pitchforkInner':
        annotation.update({
          typeOptions: {
            innerBackground: {
              fill: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
            },
          },
        });
        break;

      case 'pitchforkOuter':
        annotation.update({
          typeOptions: {
            outerBackground: {
              fill: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
            },
          },
        });
        break;

      case 'pitchforkLines':
        annotation.update({
          shapeOptions: {
            fill: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
            stroke: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
          },
        });
        break;

      case 'tunnelBackground':
        annotation.update({
          typeOptions: {
            background: {
              fill: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
            },
          },
        });
        break;

      case 'tunnelLines':
        annotation.update({
          typeOptions: {
            line: {
              stroke: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
            },
          },
        });
        break;

      case 'verticalConnector':
        annotation.update({
          typeOptions: {
            connector: {
              fill: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
              stroke: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
            },
          },
        });
        break;

      case 'verticalLabel':
        annotation.update({
          typeOptions: {
            label: {
              style: {
                color: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
              },
            },
          },
        });
        break;

      case 'flags':
        annotation.update({
          options: {
            
          }
        })
      default:
        console.warn('Unknown field in annotation update:', field);
    }
  }, [annotation]);


  const handleInputChange = (field, value) => {
    updateAnnotation(field, value);
  };

  const handleRemoveAnnotation = () => {
    if (annotation) {
      annotation.remove();
      setActivePicker(null);
      const popup = document.getElementById('annotation-popup');
      if (popup) {
        popup.style.display = 'none';
      }
      annotation = null;
    }
  };

  const renderContent = () => {
    console.log('annotation', annotation);
    
    if (!annotation || !annotation.options) return <div>No annotation selected</div>;

    switch (annotation.options.type) {
      case 'basicAnnotation':
        return (
          <div className="popup-container" ref={pickerRef}>
            <ColorPickerField
              color={colors.backgroundColor}
              setColor={color => handleInputChange('backgroundColor', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="background"
              colorFormat="rgba"
              title="Color de fondo"
            />
            <ColorPickerField
              color={colors.borderColor}
              setColor={color => handleInputChange('borderColor', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="border"
              colorFormat="rgba"
              title="Color del borde"
            />
            {annotation.options.langKey === 'label' && (
              <>
                <ColorPickerField
                  color={colors.textColor}
                  setColor={color => handleInputChange('textColor', color)}
                  activePicker={activePicker}
                  setActivePicker={setActivePicker}
                  pickerType="text"
                  colorFormat="hex"
                  title="Color del texto"
                />
                <div className="font-field">
                  <input
                    type="number"
                    value={colors.fontSize}
                    onChange={e => handleInputChange('fontSize', e.target.value)}
                    placeholder="Tamaño de la fuente"
                  />
                </div>
              </>
            )}
          </div>
        );


      case 'infinityLine':
      case 'elliottWave':
      case 'crookedLine':
        return (
          <div className="popup-container" ref={pickerRef}>
            <ColorPickerField
              color={colors.lineColor}
              setColor={color => handleInputChange('lineColor', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="line"
              colorFormat="hex"
              title="Color de la línea"
            />
            <div className="font-field">
              <input
                type="number"
                value={colors.lineSize}
                onChange={e => handleInputChange('lineSize', e.target.value)}
              />
            </div>
            {annotation.options.type === 'elliottWave' && (
              <>
                <ColorPickerField
                  color={colors.elliottWaveColor}
                  setColor={color => handleInputChange('elliottWaveColor', color)}
                  activePicker={activePicker}
                  setActivePicker={setActivePicker}
                  pickerType="elliottWave"
                  colorFormat="hex"
                  title="Color del Elliott Wave"
                />
                <div className="font-field">
                  <input
                    type="number"
                    value={colors.elliottWaveSize}
                    onChange={e => handleInputChange('elliottWaveSize', e.target.value)}
                  />
                </div>
              </>
            )}
          </div>
        );

      case 'measure':
        const measureType = annotation.options.typeOptions.selectType;

        return (
          <div className="popup-container" ref={pickerRef}>
            {/* Configuraciones comunes para Measure */}
            <ColorPickerField
              color={colors.measureBackground}
              setColor={color => handleInputChange('measureBackground', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="background"
              colorFormat="rgba"
              title="Color de fondo"
            />
            <ColorPickerField
              color={colors.measureText}
              setColor={color => handleInputChange('measureText', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="text"
              colorFormat="hex"
              title="Color del texto"
            />
            <div className="font-field">
              <input
                type="number"
                value={colors.measureWidth}
                onChange={e => handleInputChange('measureWidth', e.target.value)}
                placeholder="Grosor del texto"
              />
            </div>

            {/* Configuraciones específicas basadas en selectType */}
            {measureType.includes('x') && (
              <div className="measure-x-container">
                <ColorPickerField
                  color={colors.colorX}
                  setColor={color => handleInputChange('colorX', color)}
                  activePicker={activePicker}
                  setActivePicker={setActivePicker}
                  pickerType="lineX"
                  colorFormat="hex"
                  title="Color de la línea X"
                />
                <div className="font-field">
                  <input
                    type="number"
                    value={colors.widthX}
                    onChange={e => handleInputChange('widthX', e.target.value)}
                    placeholder="Grosor de línea X"
                  />
                </div>
              </div>
            )}

            {measureType.includes('y') && (
              <div className="measure-y-container">
                <ColorPickerField
                  color={colors.colorY}
                  setColor={color => handleInputChange('colorY', color)}
                  activePicker={activePicker}
                  setActivePicker={setActivePicker}
                  pickerType="lineY"
                  colorFormat="hex"
                  title="Color de la línea Y"
                />
                <div className="font-field">
                  <input
                    type="number"
                    value={colors.widthY}
                    onChange={e => handleInputChange('widthY', e.target.value)}
                    placeholder="Grosor de línea Y"
                  />
                </div>
              </div>
            )}
          </div>
        );


      case 'timeCycles':
      case 'fibonacciTimeZones':
        return (
          <div className="popup-container" ref={pickerRef}>
            <ColorPickerField
              color={colors.timeColor}
              setColor={color => handleInputChange('timeColor', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="line"
              colorFormat="rgba"
              title="Color de la línea"
            />
            <input
              type="number"
              value={colors.timeWidth}
              onChange={e => handleInputChange('timeWidth', e.target.value)}
            />
          </div>
        );

      case 'fibonacci':
        return (
          <div className="popup-container" ref={pickerRef}>
            <ColorPickerField
              color={colors.fibonacciBackground}
              setColor={color => handleInputChange('fibonacciBackground', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="background"
              colorFormat="rgba"
              title="Color de fondo"
            />
            <ColorPickerField
              color={colors.fibonacciLines}
              setColor={color => handleInputChange('fibonacciLines', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="lines"
              colorFormat="rgba"
              title="Color de las líneas"
            />
            <ColorPickerField
              color={colors.fibonacciLabels}
              setColor={color => handleInputChange('fibonacciLabels', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="labels"
              colorFormat="rgba"
              title="Color de las etiquetas"
            />
            <div className="font-field">
              <input
                type="number"
                value={colors.fibonacciLabelsSize}
                onChange={e => handleInputChange('fibonacciLabelsSize', e.target.value)}
              />
            </div>
          </div>
        );

      case 'pitchfork':
        return (
          <div className="popup-container" ref={pickerRef}>
            <ColorPickerField
              color={colors.pitchforkInner}
              setColor={color => handleInputChange('pitchforkInner', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="pitchforkInner"
              colorFormat="rgba"
              title="Color del interior"
            />
            <ColorPickerField
              color={colors.pitchforkOuter}
              setColor={color => handleInputChange('pitchforkOuter', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="pitchforkOuter"
              colorFormat="rgba"
              title="Color del exterior"
            />
            <ColorPickerField
              color={colors.pitchforkLines}
              setColor={color => handleInputChange('pitchforkLines', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="pitchforkLines"
              colorFormat="rgba"
              title="Color de las líneas"
            />
          </div>
        );

      case 'tunnel':
        return (
          <div className="popup-container" ref={pickerRef}>
            <ColorPickerField
              color={colors.tunnelBackground}
              setColor={color => handleInputChange('tunnelBackground', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="tunnelBackground"
              colorFormat="rgba"
              title="Color de fondo"
            />
            <ColorPickerField
              color={colors.tunnelLines}
              setColor={color => handleInputChange('tunnelLines', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="tunnelLines"
              colorFormat="rgba"
              title="Color de las líneas"
            />
          </div>
        );

      case 'verticalLine':
        return (
          <div className="popup-container" ref={pickerRef}>
            <ColorPickerField
              color={colors.verticalConnector}
              setColor={color => handleInputChange('verticalConnector', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="verticalConnector"
              colorFormat="rgba"
              title="Color del conector"
            />
            <ColorPickerField
              color={colors.verticalLabel}
              setColor={color => handleInputChange('verticalLabel', color)}
              activePicker={activePicker}
              setActivePicker={setActivePicker}
              pickerType="verticalLabel"
              colorFormat="rgba"
              title="Color de la etiqueta"
            />
          </div>
        );
      case 'flags':
        return (
          <div className="popup-container" ref={pickerRef}>
            <div className="font-field">
              <input
                type="text"
                value={colors.flagText}
                onChange={e => handleInputChange('flagText', e.target.value)}
                placeholder="Texto del flag"
              />
            </div>
            <div className="font-field">
              <input
                type="text"
                value={colors.flagName}
                onChange={e => handleInputChange('flagName', e.target.value)}
                placeholder="Nombre del flag"
              />
            </div>
          </div>
        );
      default:
        return <div>No inputs available for this type</div>;
    }
  };


  // Cerrar los selectores si se hace clic fuera del contenedor
  useEffect(() => {
    const handleClickOutside = event => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setActivePicker(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div id="annotation-popup" className="annotation-popup">
      <div className="popup-title">{annotation?.options?.langKey || 'Sin anotación'}</div>
      {renderContent()}
      <div className="popup-close" onClick={handleRemoveAnnotation} />
    </div>
  );
});

export default PopUp;