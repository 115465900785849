import React, { useEffect, useState } from 'react';
import './App.css';
import Chart from './components/chart/Chart';
import StockTools from './components/chart/StockTools';
import Tablas from './components/tables/Tablas';
import Login from './components/auth/Login';
import Navbar from './components/layout/Navbar';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { AuthProvider, useAuth } from './components/context/AuthContext';
import { DataProvider, useData } from './components/context/DataContext';
import { UIProvider, useUI } from './components/context/UIContext';
import { WebSocketProvider } from './components/context/WebSocketContext';
import { ThemeProvider, useTheme } from './components/context/ThemeContext';
import SelectFutureModal from './components/modals/SelectFutureModal';
import CompareFuturesModal from './components/modals/CompareFuturesModal';
import SavedChartsModal from './components/modals/SavedChartsModal';
import ChangeColorsModal from './components/modals/ChangeColorsModal'; // Importamos el nuevo modal
import Loading from './components/chart/Loading';

function App() {
  return (
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <DataProvider>
            <UIProvider>
              <AppContent />
            </UIProvider>
          </DataProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}

function AppContent() {
  const { token, user, alertMessage, setAlertMessage, handleLogout } = useAuth();
  const { symbol, setHistoricalData, setTodayData, setFutureData, setIndexData } = useData();
  const { theme } = useTheme();
  const { showSelectFutureModal, showCompareFuturesModal, showSavedChartsModal, showChangeColorsModal, isFullScreen } = useUI();
  
  const [loading, setLoading] = useState(true); // Estado de loading

  // Simular carga de datos (esto se puede reemplazar con tu lógica real de datos)
  useEffect(() => {
    document.body.className = theme;
    
    // Simulamos la carga de datos
    const fetchData = async () => {
      // Simulamos un delay de carga
      await new Promise(resolve => setTimeout(resolve, 3000));
      setLoading(false); // Cambiamos el estado de loading a false cuando los datos estén listos
    };

    fetchData();
  }, [theme]);

  return (
    <WebSocketProvider
      token={token}
      symbol={symbol}
      setHistoricalData={setHistoricalData}
      setTodayData={setTodayData}
      setFutureData={setFutureData}
      setIndexData={setIndexData}
    >
      <div className={`App`}>
        {alertMessage && (
          <Alert variant="warning" onClose={() => setAlertMessage('')} dismissible>
            {alertMessage}
          </Alert>
        )}

        {token ? (
          <>
            <Navbar user={user} handleLogout={handleLogout} />
            {loading ? (
              <Loading /> // Mostrar componente Loading si el estado loading es true
            ) : (
              <div className={`main-container ${isFullScreen ? 'full-screen' : ''}`}>
                <StockTools />
                <Chart setLoading={setLoading} />
                <Tablas />
              </div>
            )}
            <Routes>
              <Route path="*" element={<Navigate to="/futuros" />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </div>

      {/* Renderizar todos los modales */}
      {showSelectFutureModal && <SelectFutureModal />}
      {showCompareFuturesModal && <CompareFuturesModal />}
      {showSavedChartsModal && <SavedChartsModal />}
      {showChangeColorsModal && <ChangeColorsModal />}
    </WebSocketProvider>
  );
}

export default App;