import React from 'react';

const TablaFuturos = ({ futureData, todayData, getValue }) => {
  const marketData = futureData.length > 0 ? futureData[0].marketData : {};

  // Ajustar datos de operaciones para la tabla de trades
  const adjustedTradesData = todayData.map(trade => ({
    ...trade,
    datetime: new Date(new Date(trade.datetime).getTime() - 3 * 60 * 60 * 1000) // Restar 3 horas
  })).reverse(); // Invertir los datos para mostrar el más reciente primero

  return (
    <>
      <div>
        <h6 className="table-title">Symbol</h6>
        <table>
          <tbody>
            <tr>
              <td>Precio Ajuste</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Último Cierre</td>
              <td>{getValue(marketData.OI)}</td>
            </tr>
            <tr>
              <td>Volumen Nominal</td>
              <td>{getValue(marketData.SE?.price)}</td>
            </tr>
            <tr>
              <td>Volumen efectivo (ARS)</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Interés Abierto</td>
              <td>{getValue(marketData.LA)}</td>
            </tr>
            <tr>
              <td>Vencimiento</td>
              <td>{getValue(marketData.TV)}</td>
            </tr>
          </tbody>
        </table>  
      </div>
      
      <div>
        <h6 className="table-title">Ofertas del Book</h6>
        <table className="table-with-scroll">
          <thead>
            <tr>
              <th className="text-center">Compra</th>
              <th className="text-center">Venta</th>
            </tr>
          </thead>
          <tbody className="scrollable-tbody">
            <tr>
              <td>
                {marketData.BI && marketData.BI.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th className="text-center">Precio</th>
                        <th className="text-center">Tamaño</th>
                      </tr>
                    </thead>
                    <tbody>
                      {marketData.BI.map((item, index) => (
                        <tr key={index}>
                          <td>{getValue(item.price)}</td>
                          <td>{getValue(item.size)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-data-message">Sin ofertas</div>
                )}
              </td>
              <td>
                {marketData.OF && marketData.OF.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th className="text-center">Precio</th>
                        <th className="text-center">Tamaño</th>
                      </tr>
                    </thead>
                    <tbody>
                      {marketData.OF.map((item, index) => (
                        <tr key={index}>
                          <td>{getValue(item.price)}</td>
                          <td>{getValue(item.size)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-data-message">Sin ofertas</div>
                )}
              </td>
            </tr>
          </tbody>
        </table>  
      </div>

      <div>
        <h6 className="table-title">Últimas Operaciones</h6>
        <table className="table-with-scroll">
          <thead>
            <tr>
              <th className="text-center">Hora</th>
              <th className="text-center">Precio</th>
              <th className="text-center">Volumen</th>
            </tr>
          </thead>
          <tbody className="scrollable-tbody">
            {adjustedTradesData.length === 0 ? (
              <tr>
                <td colSpan="3" className="text-center">
                  <div className="no-data-message">No se realizaron operaciones aún.</div>
                </td>
              </tr>
            ) : (
              adjustedTradesData.map((trade, index) => (
                <tr key={index}>
                  <td>{trade.datetime.toLocaleTimeString()}</td>
                  <td>{trade.price}</td>
                  <td>{trade.size}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>  
      </div>

      
    </>
  );
};

export default TablaFuturos;
